import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Sidebar from './SideBar';
import useAuthRedirect from "./Componentes/auth";

const cardImages = {
  DAFA: "/img/dafaLogo.png",
  FUGA: "/img/fugarLogo.png",
  INVE: "/img/idafaLogo.png",
  TRAN: "/img/extasisLogo.png" 
};

const DestinationPage = () => {
  useAuthRedirect();
  const [cookies] = useCookies(['selectedCard']);
  const imageUrl = cardImages[cookies.selectedCard]
  const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
  const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
  const [isInventario, setisIventatioCollapsed] = useState(true);
  const [isPrevencion, setisPrevencionCollapsed] = useState(true);
  const [isLogistica, setisLogisticaCollapsed] = useState(true);

  const toggleCollapse = (collapseSetter) => {
    collapseSetter(prevState => !prevState);
  };

  return (
    <div id="page-top">
    <Sidebar
      imageUrl={imageUrl}
      isComponentsCollapsed={isComponentsCollapsed}
      toggleCollapse={toggleCollapse}
      setIsComponentsCollapsed={setIsComponentsCollapsed}
      isUtilitiesCollapsed={isUtilitiesCollapsed}
      setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
      isInventario={isInventario}
      setisIventatioCollapsed={setisIventatioCollapsed}
      isPrevencion={isPrevencion}
      setisPrevencionCollapsed={setisPrevencionCollapsed}
      isLogistica={isLogistica}
      setisLogisticaCollapsed={setisLogisticaCollapsed}
    />
     </div>
     
  );
}

export default DestinationPage;