import React from 'react'
import { Dropdown } from 'react-bootstrap';
import useLogout from "./Componentes/useLogout";

function UserPage({ cookies }) {
    const logout = useLogout(); // Obtén la función logout

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
            </button>
            <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{cookies.usuario}</span>
                        <img
                            className="img-profile"
                            src="/img/perfilM.svg"
                            alt="Profile"
                            style={{
                                width: '40px',
                                height: '40px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                            }}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* <Dropdown.Item href="#profile">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i> Profile
                        </Dropdown.Item>
                        <Dropdown.Item href="#settings">
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i> Settings
                        </Dropdown.Item>
                        <Dropdown.Item href="#activity">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i> Activity Log
                        </Dropdown.Item>
                        <Dropdown.Divider /> */}
                        <Dropdown.Item onClick={logout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Salir
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        </nav>
    )
}

export default UserPage