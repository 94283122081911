import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useAuthRedirect = () => {
    const [cookies] = useCookies(["usuario"]);
    const navigate = useNavigate();

    useEffect(() => {
        // Si no existe la cookie de usuario, redirigir al login
        if (!cookies.usuario) {
            navigate("/login");
        }
    }, [cookies, navigate]);
};

export default useAuthRedirect;