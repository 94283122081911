import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import useAuthRedirect from "./Componentes/auth";
import { validarCotizacionVenta } from './Validations/TrabajadoresValidation';
import config from './Componentes/config';
import { formatRemuneracion, cleanRemuneracion } from './Validations/TrabajadoresValidation';
import './ccss/App.css';
import { generarPDFVenta } from "./Documentos/CotizacionVentaPDF";
import Axios from 'axios';
import Swal from 'sweetalert2';
import { registrarLog } from './Componentes/logService';

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png",
    INVE: "/img/idafaLogo.png",
    TRAN: "/img/extasisLogo.png"
};



// Estados para el componente Trabajadores


function CotizacionVenta() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const [cookiesEmpresa] = useCookies(['idEmpresa']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const [nombreEmpresa, setnombreEmpresa] = useState("");
    const [direccion, setDireccion] = useState("");
    const [rutempresa, setrutempresa] = useState("");
    const [numerocotizacion, setnumerocotizacion] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setemail] = useState("");
    const [duracionCotizacion, setDuracionCotizacion] = useState("");
    const [CondicionPago, setCondicionPago] = useState("");
    const [TituloCotizacion, setTituloCotizacion] = useState("");
    const [Observacion, setObservacion] = useState("");
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const [utilidad, setUtilidad] = useState("");
    const [idCotizacion, setidCotizacion] = useState("");
    const [listadoCotizacion, setListadoCotizacion] = useState([]);
    const [editar, setEditar] = useState(false);
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const [rows, setRows] = useState([
        { Item: 1, Cantidad: "", Unidad: "", Articulos: "", Valor: "", valorNeto: "" }
    ]);
    const agregarFila = () => {
        setRows([
            ...rows,
            {
                Item: rows.length + 1, // Incrementa automáticamente
                Cantidad: "",
                Unidad: "",
                Articulos: "",
                Valor: "",
                valorNeto: ""
            },
        ]);
    };
    const actualizarFila = (index, field, value) => {
        const newRows = rows.map((row, i) =>
            i === index ? { ...row, [field]: value } : row
        );

        if (field === "Valor" || field === "Cantidad" || field === "utilidad") {
            const cantidad = parseInt(newRows[index].Cantidad) || 0;
            const valor = parseInt(newRows[index].Valor) || 0;
            const valorCU = valor * (1 + (utilidad / 100));
            const valorNeto = valorCU * cantidad;

            newRows[index].valorCU = formatRemuneracion(Math.round(valorCU).toString());
            newRows[index].valorNeto = formatRemuneracion(Math.round(valorNeto).toString());
        }

        setRows(newRows);
    };



    const calcularTotal = () => {
        return rows.reduce((total, row) => {
            // Verifica si row.valorSinIVA es una cadena válida antes de aplicar replace
            const valorNeto = row.valorNeto && typeof row.valorNeto === "string"
                ? Number(row.valorNeto.replace(/\./g, "").replace(/,/g, "")) // Elimina puntos y comas para convertir en número
                : 0; // Si no es válido, asigna 0
            return total + valorNeto;
        }, 0);
    };

    const eliminarFila = async (index, id) => {
        try {
            const res = await Swal.fire({
                title: '¡Cuidado!',
                html: `¿Está seguro de eliminar el item de la cotización?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar!'
            });

            if (res.isConfirmed) {
                await Axios.delete(`${config.API_URL}/EliminaItemCotizacionVenta/${id}`);
                // Registrar el log
                await registrarLog({
                    Usuario: cookies.usuario,
                    Modulo: 'Cotizacion Venta',
                    Detalle: `Delete item de Cotizacion venta: ${id}`,
                    Estado: 'Éxito',
                });

                // Eliminar la fila de la tabla
                const nuevasFilas = rows.filter((_, i) => i !== index);
                setRows(nuevasFilas);
                Swal.fire(
                    'Eliminado!',
                    'El item de la cotizacion ha sido eliminado exitosamente',
                    'success'
                );
            }
        } catch (error) {
            console.error('Error al eliminar la cotización:', error);
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion Venta',
                Detalle: `Delete de item Cotizacion venta: ${id} - error ${error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al eliminar el item de la cotización. Intente nuevamente.',
                'error'
            );
        }
    };

    const rows2 = rows.map(row => ({
        item: row.Item,
        cantidad: row.Cantidad, // Descripción de la fila
        unidad: row.Unidad,        // Cantidad de la fila
        articulos: row.Articulos,
        valor: row.valorCU,  // Valor neto formateado con 2 decimales
        valorneto: row.valorNeto,    // Valor mes formateado con 2 decimales
    }));

    const datosCotizacion = {
        nombreEmpresa,
        empresa,
        direccion,
        numerocotizacion,
        rutempresa,
        telefono,
        email,
        duracionCotizacion,
        CondicionPago,
        TituloCotizacion,
        Observacion,
        idCotizacion
    };

    const RegistraCotizacion = async () => {
        const mensajeError = validarCotizacionVenta(cookies.idEmpresa, nombreEmpresa, direccion, rutempresa, duracionCotizacion, CondicionPago, TituloCotizacion, utilidad);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idEmpresa: cookiesEmpresa.idEmpresa,
            NombreEmpresa: nombreEmpresa,
            Direccion: direccion,
            RutEmpresa: rutempresa,
            Telefono: telefono,
            Email: email,
            duracionCotizacion: duracionCotizacion,
            CondicionPago: CondicionPago,
            TituloCotizacion: TituloCotizacion,
            Observacion: Observacion,
            utilidad: utilidad
        };
        try {
            const response = await Axios.post(`${config.API_URL}/RegistraCotizacionVenta`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const idGenerado = response.data.idGenerado;
            setidCotizacion(idGenerado);
            guardarTabla(idGenerado);
            cargaCotizacion();
            // Registrar el log (llamamos a la función desacoplada)
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion Venta',
                Detalle: `Registro de cotizacion`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'La cotizacion se registro exitosamente',
                'success'
            );
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion venta',
                Detalle: `Registro de cotizacion - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    }

    const guardarTabla = async (idGenerado) => {
        try {
            const datos = rows.map(row => ({
                item: row.Item || "",
                cantidad: Number(row.Cantidad) || 0,
                unidad: row.Unidad || "",
                articulos: row.Articulos || "",
                valor: row.Valor ? Number(row.Valor.toString().replace(/[$.]/g, "")) || 0 : 0
            }));

            const respuesta = await Axios.post(`${config.API_URL}/guardarTablaVenta`, {
                idCotizacion: idGenerado,
                datos
            });

            Swal.fire("Éxito", "Tabla guardada correctamente.", "success");
        } catch (error) {
            console.error(error);
            Swal.fire("Error", "Ocurrió un error al guardar la tabla.", "error");
        }
    };

    const actualizarTabla = async (idCotizacion) => {
        try {
            // Prepara los datos de la tabla para el update
            const datos = rows.map(row => ({
                ID: row.ID,
                item: row.Item || "",
                cantidad: Number(row.Cantidad) || 0,
                unidad: row.Unidad || "",
                articulos: row.Articulos || "",
                valor: row.Valor ? Number(row.Valor.toString().replace(/[$.]/g, "")) || 0 : 0
            }));

            // Realiza la solicitud de actualización
            const respuesta = await Axios.post(`${config.API_URL}/ActualizaItemCotVenta`, {
                datos, idCotizacion
            });
        } catch (error) {
            console.error("Error al actualizar la tabla:", error);
        }
    };

    const cargaCotizacion = () => {
        const idEmpresa = cookiesEmpresa.idEmpresa;
        Axios.get(`${config.API_URL}/cargaCotizacionVenta/${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setListadoCotizacion(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setListadoCotizacion([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    const obtenerCotizacion = async (idCotizacion) => {
        try {
            const response = await Axios.get(`${config.API_URL}/obtenerCotizacionVenta/${idCotizacion}`);
            if (response.data.success) {
                setEditar(true);
                const { cotizacion, costosVariables } = response.data;
                console.log("Datos de la cotización:", response.data.cotizacion);
                console.log("Costos variables:", response.data.costosVariables);
                setnombreEmpresa(cotizacion.Nombre_Empresa);
                setDireccion(cotizacion.Direccion);
                setrutempresa(cotizacion.RutEmpresa);
                setTelefono(cotizacion.Telefono);
                setemail(cotizacion.Email);
                setDuracionCotizacion(cotizacion.DuracionCotizacion);
                setCondicionPago(cotizacion.CondicionPago);
                setTituloCotizacion(cotizacion.TituloCotizacion);
                setObservacion(cotizacion.Observacion);
                setUtilidad(cotizacion.Utilidad);
                setidCotizacion(cotizacion.ID);
                setRows([...costosVariables]);
                actualizarCostosVariables(costosVariables, cotizacion.Utilidad);
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error al obtener la cotización:", error);
        }
    };

    const UpdateCotizacionVenta = async () => {
        const mensajeError = validarCotizacionVenta(cookies.idEmpresa, nombreEmpresa, direccion, rutempresa, duracionCotizacion, CondicionPago, TituloCotizacion, utilidad);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idCotizacion: idCotizacion,
            idEmpresa: cookiesEmpresa.idEmpresa,
            NombreEmpresa: nombreEmpresa,
            Direccion: direccion,
            RutEmpresa: rutempresa,
            Telefono: telefono,
            Email: email,
            duracionCotizacion: duracionCotizacion,
            CondicionPago: CondicionPago,
            TituloCotizacion: TituloCotizacion,
            Observacion: Observacion,
            utilidad: utilidad
        };
        try {
            const response = await Axios.post(`${config.API_URL}/UpdateCotizacionVenta`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            actualizarTabla(idCotizacion);
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion',
                Detalle: `actualizacion de cotizacion venta`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'La cotizacion se actualizo exitosamente',
                'success'
            );
            limpiarCampos();
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion',
                Detalle: `Actualizacion de cotizacion venta - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    };

    const limpiarCampos = () => {
        setnombreEmpresa("");
        setDireccion("");
        setrutempresa("");
        setTelefono("");
        setemail("");
        setDuracionCotizacion("");
        setCondicionPago("");
        setTituloCotizacion("");
        setObservacion("");
        setUtilidad("");
        setEditar(false);
    }

    const actualizarCostosVariables = (costosVariables, utilidad) => {
        if (costosVariables && costosVariables.length > 0) {
            const datosActualizados = costosVariables.map((row) => {
                const cantidad = parseInt(row.Cantidad) || 0;
                const valor = parseInt(row.Valor) || 0;
                const valorCU = valor * (1 + (utilidad / 100));
                const valorNeto = valorCU * cantidad;

                return {
                    ...row,
                    valorCU: formatRemuneracion(Math.round(valorCU).toString()),
                    valorNeto: formatRemuneracion(Math.round(valorNeto).toString()),
                };
            });
            setRows(datosActualizados);
        }
    };

    const DeleteCotizacionVenta = async (id) => {
        try {
            const res = await Swal.fire({
                title: '¡Cuidado!',
                html: '¿Está seguro de eliminar la cotización?.',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar!'
            });

            if (res.isConfirmed) {
                await Axios.delete(`${config.API_URL}/EliminarCotizacionVenta/${id}`);
                await registrarLog({
                    Usuario: cookies.usuario,
                    Modulo: 'Cotizacion Venta',
                    Detalle: `Delete de Cotizacion venta: ${id}`,
                    Estado: 'Éxito',
                });
                cargaCotizacion();
                limpiarCampos();

                Swal.fire(
                    'Eliminado!',
                    'La cotización se ha sido eliminado exitosamente',
                    'success'
                );
            }
        } catch (error) {
            console.error('Error al eliminar la cotizacion:', error);
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion Venta',
                Detalle: `Delete de Cotizacion venta: ${id} - error ${error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al eliminar la cotización. Intente nuevamente.',
                'error'
            );
        }
    }

    useEffect(() => {
        cargaCotizacion();
    }, []);

    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Crear cotizaciones de venta para {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="input-group mb-3">
                                                <div class="col-md-6">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Nombre empresa:</span>
                                                        <input type="text" class="form-control" value={nombreEmpresa} onChange={(event) => { setnombreEmpresa(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Direccion:</span>
                                                        <input type="text" class="form-control" value={direccion} onChange={(event) => { setDireccion(event.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">RUT:</span>
                                                        <input type="text" class="form-control" value={rutempresa} onChange={(event) => { setrutempresa(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Telefono:</span>
                                                        <input type="text" class="form-control" value={telefono} onChange={(event) => { setTelefono(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">E-mail:</span>
                                                        <input type="text" class="form-control" value={email} onChange={(event) => { setemail(event.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Duración cotización:</span>
                                                        <input type="text" class="form-control" value={duracionCotizacion} onChange={(event) => { setDuracionCotizacion(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Condición pago:</span>
                                                        <input type="text" class="form-control" value={CondicionPago} onChange={(event) => { setCondicionPago(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Titulo cotización:</span>
                                                        <input type="text" class="form-control" value={TituloCotizacion} onChange={(event) => { setTituloCotizacion(event.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="col-md-12">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Observación:</span>
                                                        <input type="text" class="form-control" value={Observacion} onChange={(event) => { setObservacion(event.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Utilidad %:</span>
                                                        <input type="text" class="form-control" value={utilidad} onChange={(event) => { setUtilidad(event.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th className="col-item">ID</th>
                                                            <th className="col-item">Item</th>
                                                            <th className="col-cantidad">Cantidad</th>
                                                            <th className="col-unidad">Unidad</th>
                                                            <th>Artículos</th>
                                                            <th className="col-valor">Valor S/U</th>
                                                            <th className="col-valor">Valor C/U</th>
                                                            <th className="col-valorsiva">Valor Neto</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.ID}</td>
                                                                <td>{row.Item}</td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        value={row.Cantidad}
                                                                        onChange={(e) => actualizarFila(index, "Cantidad", e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={row.Unidad}
                                                                        onChange={(e) => actualizarFila(index, "Unidad", e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={row.Articulos}
                                                                        onChange={(e) => actualizarFila(index, "Articulos", e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={row.Valor}
                                                                        onChange={(e) => actualizarFila(index, "Valor", e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>${row.valorCU}</td>
                                                                <td>${row.valorNeto}</td>
                                                                <td>
                                                                    <button onClick={() => eliminarFila(index, row.ID)} className="btn btn-danger btn-sm">
                                                                        Eliminar
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="6" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                                Total costo neto ${formatRemuneracion(calcularTotal().toString())}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <button onClick={agregarFila} class="btn btn-secondary">Agregar Fila</button>
                                                {editar ? (
                                                    <div>
                                                        <button className='btn btn-danger m-2' onClick={limpiarCampos}>Cancelar</button>
                                                        <button onClick={UpdateCotizacionVenta} class="btn btn-secondary">Guardar cotizacion</button>
                                                    </div>
                                                ) : (
                                                    <button onClick={RegistraCotizacion} class="btn btn-success">Registrar cotizacion</button>
                                                )}
                                                {/* <button class="btn btn-success">Registrar cotizacion</button> */}
                                                <button class="btn btn-primary" onClick={() => generarPDFVenta(rows2, datosCotizacion)}>Exportar PDF</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de cotizaciones</h6>
                                        </div>
                                        <div class="card-body">
                                            {
                                                <div>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th>N°</th>
                                                                    <th>Empresa</th>
                                                                    <th>Telefono</th>
                                                                    <th>Email</th>
                                                                    <th>Utilidad</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Array.isArray(listadoCotizacion) && listadoCotizacion.length > 0 ? (
                                                                        listadoCotizacion.map((val, key) => (
                                                                            <tr key={key}>
                                                                                <th scope="row">{val.ID}</th>
                                                                                <td data-label="Empresa">{val.Nombre_Empresa}</td>
                                                                                <td data-label="Telefono">{val.Telefono}</td>
                                                                                <td data-label="Email">{val.Email}</td>
                                                                                <td data-label="Utilidad">{val.Utilidad}</td>
                                                                                <td data-label="Acciones">
                                                                                    <div className="btn-group" role="group" aria-label="Basic example">
                                                                                        <button type="button" onClick={() => { obtenerCotizacion(val.ID); }} className="btn btn-info btn-sm">Seleccionar</button>
                                                                                        <button type="button" onClick={() => DeleteCotizacionVenta(val.ID)} className="btn btn-danger btn-sm">Eliminar</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr><td colSpan="15">No hay información.</td></tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body >
    )
}

export default CotizacionVenta
