import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Sidebar from './SideBar';
import Axios from 'axios';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const cardImages = {
    Dafa: "/img/dafaLogo.png",
    Fugar: "/img/fugarLogo.png"
};

function DestinationPage() {
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard];
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };

    const tableStyles = {
        styles: {
            font: 'Helvetica', // Tipo de fuente
            fontSize: 10, // Tamaño de fuente
            textColor: 0, // Color de texto negro
            overflow: 'linebreak', // Manejo de desbordamiento de texto
            halign: 'center', // Alineación horizontal
            valign: 'middle', // Alineación vertical
            lineWidth: 0.5, // Ancho de las líneas del borde
            lineColor: [0, 0, 0], // Color de las líneas del borde (negro)
        },
        headStyles: {
            fillColor: [255, 255, 255], // Color de fondo del encabezado (blanco)
            textColor: [0, 0, 0], // Color del texto del encabezado (negro)
            fontStyle: 'bold', // Estilo del texto del encabezado
        },
        alternateRowStyles: {
            fillColor: [240, 240, 240], // Color de fondo de las filas alternas
        },
        columnStyles: {
            0: {
                cellWidth: 80, // Ancho de la primera celda
            },
            1: {
                cellWidth: 80, // Ancho de la segunda celda
            },
        },
        margin: { top: 40 }, // Margen superior de la tabla
    };

    const justifyText = (doc, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(" ");
        let line = "";
        let cursorY = y;
    
        words.forEach((word, index) => {
            let testLine = line + word + " ";
            let testWidth = doc.getTextWidth(testLine);
    
            if (testWidth > maxWidth && index > 0) {
                // Calcular el espacio entre palabras
                const spaceWidth = (maxWidth - doc.getTextWidth(line.trim())) / (line.trim().split(" ").length - 1);
                const justifiedLine = line.trim().split(" ").join(" ".repeat(spaceWidth > 0 ? Math.ceil(spaceWidth) : 1));
    
                doc.text(justifiedLine, x, cursorY);
                line = word + " ";
                cursorY += lineHeight;
            } else {
                line = testLine;
            }
        });
    
        // Imprimir la última línea
        doc.text(line.trim(), x, cursorY);
        return cursorY + lineHeight;
    };

    // Función para generar el PDF
    const generatePDF = () => {
        const doc = new jsPDF();

        let cursorY = 30; // Posición inicial de Y

        // Función para verificar si se necesita una nueva página
        const checkPageEnd = (doc, cursorY, lineHeight = 10) => {
            if (cursorY + lineHeight > 280) { // Si Y está cerca del final de la página
                doc.addPage(); // Añade una nueva página
                cursorY = 10; // Reinicia la posición de Y para la nueva página
            }
            return cursorY;
        };

        // Título centrado
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text("CONTRATO DE TRABAJO", 105, 20, { align: 'center' });

        // Estilo de texto para el contenido
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");

        // Texto del primer párrafo
        const content1 = `En coronel, a 03 de agosto del año 2024, entre FUGAR SERVICIOS INDUSTRIALES SPA, R.U.T 77.040.719-2, representada legalmente por doña JARITZA JARELA GARCIAS RIFFO cédula de identidad 18.384.024-k, ambos con domicilio en Cochrane #130.Seg. Piso of.5 comuna de coronel, en adelante el “Empleador” y don(a) GERMÁN ALEXIS JUNIORS SILVA PINTO, de nacionalidad CHILENO, nacido 09 de ENERO del 1995, estado civil SOLTERO, Cotizante AFP MODELO RUT 18.816.272-K, Previsión FONASA domiciliado TENIENTE MERINO 1 PASAJE 2 #31-A, comuna de: CONCEPCIÓN en adelante “Trabajador”. Se ha convenido el siguiente CONTRATO DE TRABAJO:`;
        doc.setFontSize(10);
        doc.text(content1, 10, cursorY, { maxWidth: 190 });
        // cursorY = justifyText(doc, content1, 30, cursorY, 190, 10); // x = 10, y = cursorY, maxWidth = 190, lineHeight = 10
        cursorY += 30;
        cursorY = checkPageEnd(doc, cursorY);

        // Texto del segundo párrafo
        const content2 = `PRIMERO: El trabajador se compromete y obliga a prestar servicios como APOYO INTEGRAL DE PRODUCCION u otro trabajo o función similar, que tenga directa relación con el cargo ya indicado, en las dependencias de BODEGA GOLONDRINAS, AVENIDA GRAN BRETAÑA CALLE S/N, comuna de TALCAHUANO, en donde es enviado por FUGAR SERVICIOS INDUSTRIALES a prestar los servicios para lo que es contratado. Pudiendo ser trasladado a otro departamento, cambiado de turno del original, sección de la oficina principal o de cualquiera de las agencias del Empleador, a condiciones que se trate de labores similares, y sin que ello importe menoscabo para el trabajador, todo ello sujeto a las necesidades operativas de la Empresa. Las funciones que el trabajador realizara son:`;
        doc.text(content2, 10, cursorY, { maxWidth: 190 });

        // Aumentar la posición Y para la lista
        cursorY += 30; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        // Lista de funciones
        const functions = [
            "Consolidado de camiones, encarpado y desencarpado",
            "Recepción y despacho de harina, enganche, vaciado y llenado de maxi saco, pesaje jumbo.",
            "Asistencia en recepción, carga y descarga de aceite.",
            "Aseo en bodegas y exteriores",
            "Ensacado general (manual y automático) u otra actividad que tenga directa relación con el cargo ya indicado."
        ];

        // Establecer estilo de lista
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        // Imprimir la lista
        functions.forEach((item, index) => {
            doc.text(`• ${item}`, 20, cursorY);
            cursorY += 5; // Ajusta la distancia entre los elementos de la lista
        });

        cursorY += 7; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content3 = `SEGUNDO: JORNADA DE TRABAJO`;

        // Establecer el segundo párrafo
        doc.text(content3, 10, cursorY, { maxWidth: 190 });
        cursorY += 7; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content4 = `El trabajador cumplirá una jornada semanal ordinaria de 44 horas, según necesidades de la Empresa, en las dependencias de BODEGAS GOLONDRINAS será distribuido en los siguientes turnos rotativos: `;

        // Establecer el segundo párrafo
        doc.text(content4, 10, cursorY, { maxWidth: 190 });

        // Agregar tabla
        doc.autoTable({
            head: [['TURNO DIA', 'TURNO NOCHE']],
            body: [
                ['Lunes a viernes', 'Lunes a jueves'],
                ['8:00 – 17:00 HRS / 09:00 – 18.00', '20:00 – 06:00 HRS'],
                ['Sábado 9:00-13-00', 'Viernes 20:00-05:00'],
            ],
            startY: 137, // Un valor fijo para comenzar
            ...tableStyles, // Aplica los estilos configurados
            didParseCell: (data) => {
                const { cell } = data;
                // Establece el borde de la celda
                cell.styles.lineWidth = 0.5; // Ancho del borde
                cell.styles.lineColor = [0, 0, 0]; // Color del borde (negro)
            },
        });
        // Continúa con el resto del contenido
        const content5 = `La jornada de trabajo será interrumpida con un descanso de 60 minutos destinados a la colación, según Artículo 34 del Código del Trabajo.`;
        doc.text(content5, 10, doc.autoTable.previous.finalY + 10, { maxWidth: 190 });

        cursorY += 60; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content6 = `TERCERO Dada la naturaleza del cargo y de acuerdo a nuestro reglamento interno en TITULO V: DEL DESCANSO DOMINICAL Y/O SEMANAL; los trabajadores exceptuados de descanso dominical debido a que laboran en tareas y/o funciones que según el numero 2° del inciso 1° del artículo`;
        doc.text(content6, 10, cursorY, { maxWidth: 190 });

        cursorY += 17; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content7 = `CUARTO: El trabajador no se encuentra autorizado para realizar horas extraordinarias, sin autorización del empleador. `;
        doc.text(content7, 10, cursorY, { maxWidth: 190 });

        cursorY += 7; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content8 = `QUINTO: El empleador se compromete a remunerar los servicios del trabajado, los primeros 05 de cada mes vencido, con un sueldo base mensual de $500.000 (quinientos mil pesos) el cual se “reajustará en conformidad de la ley” y será liquidado y pagado, por periodos vencidos y en forma proporcional a los días trabajados el siguiente beneficio: `;
        doc.text(content8, 10, cursorY, { maxWidth: 190 });

        cursorY += 17; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content9 = `a)	Bono de movilización: Se otorgará un bono de 40.000 no imponibles (cuarenta mil pesos) por día efectivamente trabajado. `;
        doc.text(content9, 15, cursorY, { maxWidth: 190 });

        cursorY += 12; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content10 = `Las deducciones que la Empleadora podrá según los casos – practicar a las remuneraciones, son todas aquellas que disponen el Artículo 58 del Código del Trabajo. `;
        doc.text(content10, 10, cursorY, { maxWidth: 190 });

        cursorY += 12; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content11 = `SEXTO: El trabajador, asimismo, acepta y autoriza al Empleador para que haga las deducciones que establecen las leyes vigentes y, para que le descuente el tiempo no trabajado debido a atrasos, inasistencias o permisos y, además, la rebaja del monto de las multas establecidas en el Reglamento Interno de Orden, Higiene y Seguridad, en caso que procedieren.`;
        doc.text(content11, 10, cursorY, { maxWidth: 190 });

        cursorY += 17; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content12 = `Los atrasos reiterados y las ausencias injustificadas darán lugar a la terminación inmediata el contrato de trabajo, sin derecho a indemnización alguna. Se entenderá por atraso el ingreso del trabajor (a) después e la hora señalada en su contrato de trabajo o n el correspondiente turno: y por atraso reiterado, la ocurrencia de 3 (tres) o más atrasos por semana y/o de 6 (seis) o más atrasos es un mes, que acumulen un total de 20 (veinte) minutos o más de atrasos en el periodo mensual. Título IX: DE LAS OBLIGACIONES Y PROHIBICIONES DEL REGLAMENTO INTERNO DE FUGAR SERVICIOS INDUSTRIALES.  `;
        doc.text(content12, 10, cursorY, { maxWidth: 190 });

        cursorY += 27; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        const content13 = `SEPTIMO: La Empresa se obliga a pagar el empleador una gratificación anual equivalente al 25% (veinticinco por ciento) del total de las remuneraciones mensuales que este hubiese percibido en el año, con el tope de 4,75 Ingresos Mínimos. Esta gratificación se calculará, liquidará y anticipará mensualmente en forma coetánea con la remuneración del mes respectivo, siendo cada abono equivalente a la doceava parte de la gratificación anual. La gratificación así convenida es incompatible y sustituye a la que resulte de la aplicación de los artículos 74 y siguientes al Código del Trabajo. Para los efectos de cotejar la gratificación `;
        doc.text(content13, 10, cursorY, { maxWidth: 190 });
        cursorY += 27; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content14 = `convenido en esta cláusula con la que, según la ley, eventualmente podría corresponder al Empleado, los valores anticipados mensualmente se reajustarán con conformidad con lo dispuesto en el artículo 63 del Código del Trabajo, y se entenderá que fueron abonados con carácter de anticipos de dichas gratificaciones legales. Con todo, si las sumas anticipadas a título de gratificación convencional resultaren mayores que las que legalmente correspondieren al Empleador, el exceso se consolidará en su beneficio.`;
        doc.text(content14, 10, cursorY, { maxWidth: 190 });
        cursorY += 27; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content15 = `OCTAVO: REGISTRO DE ASISTENCIA De acuerdo al artículo N°21 para los efectos de controlas la asistencia y las horas de trabajo, tanto de la jornada ordinaria como la extraordinaria, los trabajadores y trabajadoras deberán registrar el ingreso y salida del lugar de trabajo por los medios manuales, mecánicos, magnéticos o de otra índole que FUGAR SERVICIOS INDUSTRIALES determine. El registro se deberá hacer de forma inmediata al ingreso o egreso del trabajo y, para el caso del trabajador (a) con descando con derecho a descanso entre la jornada, el registro también se debe hacer al salir y al regresar de la colacion o descanso. `;
        doc.text(content15, 10, cursorY, { maxWidth: 190 });
        cursorY += 27; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content16 = `NOVENO: Regulación general sobre todo tipo de permisos:`;
        doc.text(content16, 10, cursorY, { maxWidth: 190 });
        cursorY += 7; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const functions2 = [
            "Todo permiso que se solicite bajo cualquier circunstancia deberá estar respaldado por el respectivo comprobante de solicitud de permiso en uso en la empresa.",
            "Deberá ser solicitado con la debida anticipación, salvo que en casos imprevistos sea imposible presentarlos con anticipación y debidamente justificados",
            "Ningún trabajador (a) podrá autorizar sus propios permisos, debiendo siempre solicitarlos y obtenerlos por conducto de su superior jerárquico.",
            "Todo permiso que se solicite por horas, durante y antes del inicio o termino de la jornada de trabajo, serán autorizados directamente por el jefe correspondiente y serán sin goce de sueldo. ",
            "Se acuerda de mutuo acuerdo entre las partes  que en temporada de demanda  alta de prestación de los  servicios a  la empresa mandante, tales descansos serán gestionados cuando baje el nivel de demanda de la prestación del servicio a la empresa mandante. ",
            "En caso fortuito o de enfermedad será otorgado en la temporada alta."
        ];

        // Establecer estilo de lista
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        // Imprimir la lista
        functions2.forEach((item) => {
            const splitText = doc.splitTextToSize(item, 180); // Ajustar el texto dentro de un ancho máximo de 180
            splitText.forEach((line, index) => {
                cursorY = checkPageEnd(doc, cursorY, 10); // Verificar si necesita una nueva página
    
                if (index === 0) {
                    doc.text(`• ${line}`, 20, cursorY);
                } else {
                    // Imprimir las líneas restantes sin viñeta
                    doc.text(`${line}`, 25, cursorY); // Añadir un margen mayor para las líneas continuas
                }
                cursorY += 5; // Ajusta la distancia entre los elementos de la lista
            });
            cursorY += 2; // Ajusta el espacio entre elementos de la lista
        });
        cursorY += 2; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content17 = `DECIMO: El trabajador se obliga y compromete expresamente a cumplir las instrucciones y funciones y/o labores a realizar dictadas en este contrato de trabajo además de las que le sean impartidas por su jefe inmediato o por la Gerencia de la Empresa y, acatar en todas sus partes las disposiciones establecidas en el Reglamento de Orden, Higiene y Seguridad las que declara conocer y que, para estos efectos se consideran parte integrante del presente contrato, reglamento de cual el trabajador recibe un ejemplar en este acto. `;
        doc.text(content17, 10, cursorY, { maxWidth: 190 });
        cursorY += 25; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content18 = `El trabajador acepta que se le hizo entrega de los EPP íntegros que serán para su cuidado y protección personal, los cuales deberá usar siempre, en todo momento mientras se mantenga operando en las instalaciones, así mismo las inducciones correspondientes por parte de FUGAR SERVICIOS INDSTRIALES y así mismo de la Empresa Mandante para el autocuidado y mutuo cuidado, para lo cual se anexa un compromiso de seguridad al trabajador de no cumplir darán lugar a la terminación inmediata el contrato de trabajo, sin derecho a indemnización alguna. `;
        doc.text(content18, 10, cursorY, { maxWidth: 190 });
        cursorY += 27; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content19 = `El trabajador se compromete expresamente a cumplir a cabalidad el TITULO IV: INSTRUCCIONES BÁSICAS EN PREVENCION DE RIESGOS, del reglamento interno de FUGAR SERVICIOS INDUSTRIALES de no cumplir darán lugar a la terminación inmediata el contrato de trabajo, sin derecho a indemnización alguna. `;
        doc.text(content19, 10, cursorY, { maxWidth: 190 });
        cursorY += 57; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content20 = `DECIMO PRIMERO: El presente contrato regirá con carácter de PLAZO FIJO desde el día 03 de agosto hasta el 03 de SEPTIEMBRE del 2024, cualquiera de las partes o ambas, según el caso, podríamos ponerle termino en cualquier momento con arreglo a la ley.`;
        doc.text(content20, 10, cursorY, { maxWidth: 190 });
        cursorY += 17; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content21 = `DECIMO SEGUNDO: Se deja constancia que FUGAR SPA, actúa con el giro de otras actividades de servicios de apoyo de personas a las empresas, por lo que, si la empresa mandante solicita la desvinculación del trabajador por motivos propios, se pondrá termino a la relación laboral.`;
        doc.text(content21, 10, cursorY, { maxWidth: 190 });
        cursorY += 17; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content22 = `DECIMO TERCERO: CONFIDENCIALIDAD: EL trabajador se obliga en forma irrevocable a que toda Información que LA EMPRESA le haya proporcionado o le proporcione con motivo de este Contrato. Tendrá el carácter de confidencial, cualquiera sea la forma o formato a través del cual se exprese dicha información, sea verbalmente o por escrito, sea que se contenga en documentos, memoranda, escritos de otra naturaleza, discos, cintas, disquetes, archivos computacionales o en cualquiera otra forma. EL trabajador no divulgará, publicará ni permitirá la publicación de todo o parte de la Información Confidencial, ni ponerla a disposición de terceros, a no ser que cuente con el consentimiento explícito de LA EMPRESA. Al momento de la resolución por cualquier causa de este Contrato, EL trabajador deberá devolver, según lo indique el empleador, la Información Confidencial y toda copia, resumen o extracto de ésta, contenida en cualquier documento de trabajo, memorandos u otros escritos, discos, cintas, disquetes o archivos computacionales, sin retener copias, resúmenes o extractos de la misma, en ninguna forma.`;
        doc.text(content22, 10, cursorY, { maxWidth: 190 });
        cursorY += 45; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content23 = `DECIMO CUARTO: Del régimen previsional. Se deja constancia que el Trabajador cotiza en el régimen previsional chileno, comprometiéndose al Empleador a efectuar las retenciones y entregarlas a las instituciones correspondientes.`;
        doc.text(content23, 10, cursorY, { maxWidth: 190 });
        cursorY += 12; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content24 = `DECIMO QUINTO :  Dada la naturaleza del cargo y de acuerdo a nuestro reglamento interno en TITULO V: DEL DESCANSO DOMINICAL Y/O SEMANAL; los trabajadores exceptuados de descanso dominical debido a que laboran en tareas y/o funciones que según el numero 2° del inciso 1° del artículo 38 del código del trabajo son de aquellas explotaciones, labores o servicios exigen continuidad por la naturaleza de sus proceso, por razones de carácter técnico, por las necesidades que satisfacen o para evitar notables perjuicios al interés público o de la industria, en general, tendrá derecho a  1 día de descanso en cada semana en compensación a las actividades desarrolladas en día domingo y a otro por cada festino en que deban trabajar.`;
        doc.text(content24, 10, cursorY, { maxWidth: 190 });
        cursorY += 32; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content25 = `Al menos 2 de los días de descanso en el respectivo mes calendario deberán necesariamente otorgarse en día domingo.`;
        doc.text(content25, 10, cursorY, { maxWidth: 190 });
        cursorY += 7; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        
        const content26 = `DÉCIMO SEXTO Las partes establecen los siguientes correos electrónicos para efectos de comunicaciones y avisos que deban realizarse por medios digitales:.`;
        doc.text(content26, 10, cursorY, { maxWidth: 190 });
        cursorY += 15; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content27 = `DÉCIMO SEPTIMO: - Empleador: administracion@idafa.cl  - 
                                    Trabajador: German.silvapinto@gmail.com`;
        doc.text(content27, 10, cursorY, { maxWidth: 190 });
        cursorY += 15; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content28 = `DÉCIMO OCTAVO: Para todas las cuestiones a que eventualmente pueda dar origen este contrato, las partes fijan domicilio en la comuna de coronel.`;
        doc.text(content28, 10, cursorY, { maxWidth: 190 });
        cursorY += 15; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);

        const content29 = `El presente contrato de trabajo se firma en tres ejemplares en este mismo acto uno en poder de cada contratante.`;
        doc.text(content29, 10, cursorY, { maxWidth: 190 });
        cursorY += 15; // Ajusta este valor según sea necesario
        cursorY = checkPageEnd(doc, cursorY);
        // Guardar el PDF
        doc.save("Contrato_Trabajo.pdf");
    };






    return (
        <div className="page-top">
            <div className="sidebar">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
            </div>
            <div className="content-wrapper">
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="h3 mb-1 text-gray-800">Panel gestion de documentos</h1>
                        <hr />
                        <div className="row">
                            <div className="col-lg-8">

                                <div className="card position-relative">
                                    <div className="card-header py-3">
                                        <h6 className="m-0 font-weight-bold text-primary">Crear contrato trabajo</h6>
                                    </div>
                                    <div className="card-body">
                                        <button className='btn btn-success' onClick={generatePDF}>Registrar trabajador</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DestinationPage;
