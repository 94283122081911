// GenerarPDF.js
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatRemuneracion, cleanRemuneracion2 } from '../Validations/TrabajadoresValidation';

export const generarPDFVenta = (rows, datosSueldo) => {
    const doc = new jsPDF();
    const obtenerFechaFormateada = (diasSumar = 0) => {
        const fecha = new Date(); // Fecha actual
        fecha.setDate(fecha.getDate() + diasSumar); // Sumar días si corresponde
        const day = String(fecha.getDate()).padStart(2, '0');
        const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses de 0 a 11
        const year = fecha.getFullYear();
        return `${day}/${month}/${year}`; // Formato dd/mm/aaaa
    };
    // Encabezado
    doc.setFontSize(14);
    doc.text(String(datosSueldo.empresa), 10, 10);
    doc.setFontSize(10);
    doc.text("Cochrane 130, 2 piso oficina 05", 10, 15);
    doc.text("77.040.719-2", 10, 20);
    doc.text("56 932706706", 10, 25);
    doc.text("dfuentes@idafa.cl", 10, 30);

    doc.setFontSize(12);
    doc.text("Número", 150, 10);
    doc.text(String("CV-" +datosSueldo.idCotizacion), 180, 10);
    doc.text("Fecha", 150, 15);
    doc.text(String(obtenerFechaFormateada()), 180, 15);

   
    doc.text("Valido hasta" , 150, 20);
    doc.text(obtenerFechaFormateada(parseInt(datosSueldo.duracionCotizacion, 10)), 180, 20);

    // Sección de datos del cliente
    doc.setFontSize(12);
    doc.setFillColor(169, 169, 169);
    doc.rect(10, 35, 190, 8, "F");
    doc.text("DATOS DEL CLIENTE", 15, 40);

    doc.setFontSize(10);
    doc.text(`Nombre:  ${String(datosSueldo.nombreEmpresa)}`, 10, 50);
    doc.text(`Dirección:  ${String(datosSueldo.direccion)}`, 10, 55);
    doc.text(`RUT:  ${String(datosSueldo.rutempresa)}`, 10, 60);
    doc.text(`Teléfono:  ${String(datosSueldo.telefono)}`, 10, 65);
    doc.text(`E-mail:  ${String(datosSueldo.email)}`, 10, 70);

    const serviceColumns = ["Item", "Cantidad", "Unidad", "Artículos", "Valor", "Valor Neto"];
    const serviceRows = rows.map((row) => [
        row.item || "",
        row.cantidad,
        row.unidad,
        row.articulos,
        `$${formatRemuneracion(String(row.valor))}`,
        `$${formatRemuneracion(String(row.valorneto))}`,
    ]);

    const totalValorNeto = rows.reduce((sum, row) => sum + cleanRemuneracion2(row.valorneto || 0), 0);
    serviceRows.push(["", "", "", "", "TOTAL NETO", `$${totalValorNeto.toLocaleString()}`]);
    doc.autoTable({
        startY: 85,  // La tabla comienza después de la posición Y
        head: [serviceColumns],  // Cabecera de la tabla
        body: serviceRows,      // Cuerpo de la tabla
        styles: {
            lineColor: [0, 0, 0], // Color de la línea (negro)
            lineWidth: 0.1,        // Grosor de las líneas
            halign: 'center',      // Alineación horizontal
            textColor: [0, 0, 0],  // Color del texto (negro)
        },
        headStyles: {
            fillColor: [169, 169, 169], // Color de fondo de las celdas de encabezado
            lineColor: [0, 0, 0], // Color del borde
            lineWidth: 0.1,      // Grosor del borde en el encabezado
        },
        alternateRowStyles: {
            fillColor: [240, 240, 240], // Color alternativo de filas
        },
        columnStyles: {
            0: { cellWidth: 'auto' },  // Mostrar columna 1 (Item)
            1: { cellWidth: 'auto' },  // Mostrar columna 2 (Cantidad)
            2: { cellWidth: 'auto' },  // Mostrar columna 3 (Unidad)
            3: { cellWidth: 'auto' },  // Mostrar columna 4 (Artículos)
            4: { cellWidth: 'auto' },  // Mostrar columna "Valor"
            5: { cellWidth: 'auto' },  // Mostrar columna "Valor Neto"
        },
        didDrawPage: function (data) {
            const pageWidth = doc.internal.pageSize.width; // Usar el ancho de la página
            const marginLeft = data.settings.margin.left || 0; // Asegurarse de que margin.left esté definido
            const textWidth = pageWidth - 2 * marginLeft; // Calcular el ancho de la página sin los márgenes
        
            // Ajuste del texto centrado
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            doc.text(datosSueldo.TituloCotizacion, marginLeft + textWidth / 2, 80, { align: "center" });
            const yPosition = data.cursor.y + 10; // Ajustar la posición vertical, después de la tabla

            doc.setFontSize(10);  // Cambiar el tamaño de la fuente para los textos adicionales
            doc.setFont("helvetica", "normal");
    
            // Condición de pago
            doc.text("Condición de pago: "+ datosSueldo.CondicionPago, marginLeft, yPosition);
    
            // Observación
            doc.text("Observación: "+ datosSueldo.Observacion, marginLeft, yPosition + 5); 
        },
    });

    // const subtotal = Number(datosSueldo.TotalFijoTrabajador);

    // // Calcular el IVA como el 19% del Sub-total
    // const iva = cleanRemuneracion2(subtotal) * 0.19;

    // // Calcular el Total sumando el Sub-total y el IVA
    // const total = subtotal + Math.round(iva);

    // // Coloca el texto en el documento PDF
    // const finalY = doc.lastAutoTable.finalY + 10;
    // doc.text("SUB-TOTAL:", 150, finalY);
    // doc.text("$" + formatRemuneracion(String(subtotal)), 180, finalY);

    // Guardar PDF
    doc.save("Cotización_CV_"+ datosSueldo.idCotizacion+".pdf");
};

export default generarPDFVenta;
