import Axios from 'axios';
import config from '../Componentes/config';

export const registrarLog = async ({ Usuario, Modulo, Detalle, Estado }) => {
    try {
        // Datos del log
        const logData = {
            Usuario,
            Modulo,
            Detalle,
            Estado,
        };

        // Enviar la solicitud para registrar el log
        const response = await Axios.post(`${config.API_URL}/registraLog`, logData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        // Si la respuesta es exitosa, mostramos una alerta
        console.log('Log registrado exitosamente:', response.data);
        return response.data;
    } catch (error) {
        // Manejo de errores
        console.error('Error al registrar el log:', error);
    }
};
