import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ValidatingLogin from './Validations/LoginValidation';
import { useCookies } from 'react-cookie';
import Axios from 'axios';
import config from './Componentes/config';
import { registrarLog } from './Componentes/logService';

function Login() {
    const [values, setValues] = useState({
        usuario: '',
        password: ''
    })
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['usuario']);
    const [errors, setErrors] = useState({})
    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    }


    const handleSubmit = async (event) => {
        event.preventDefault();      
        try {
            const validationErrors = ValidatingLogin(values);
            setErrors(validationErrors);
            
            if (!validationErrors.usuario && !validationErrors.password) {
                const res = await Axios.post(`${config.API_URL}/login`, values);
                console.log("1er"+res.data); // Verifica la respuesta del servidor
                
                if (res.data.status === "Success") {
                    const userId = res.data.userId; // Obtén el ID del usuario
                    console.log("ID del usuario:", userId);
                    await registrarLog({
                        Usuario: cookies.usuario,
                        Modulo: 'Login',
                        Detalle: `Inicio de sesión: ${values.usuario}`,
                        Estado: 'Éxito',
                    });
    
                    setCookie("usuario", values.usuario);
                    setCookie("userId", userId); // Guarda el ID del usuario en las cookies si es necesario
                    navigate('/prehome'); // Navegar si el login es exitoso
                } else {
                    alert("Usuario no existe");
                    console.log(res.data);
                }
            }
        } catch (err) {
            console.error("Error en la solicitud:", err);
            alert("Ocurrió un error. Intenta nuevamente.");
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Login',
                Detalle: `Fallo inicio de sesión: ${err}`,
                Estado: 'Fallo',
            });
        }
    };

    return (
        <div className="login-container d-flex align-items-center justify-content-center bg-gradient-primary">
            <div className="login-form p-4 rounded shadow">
                <form onSubmit={handleSubmit} className="user">
                    <div className="text-center mb-4">
                        <img src="/img/IDAFA.png" alt="logo" style={{ maxWidth: '100%', height: 'auto', width: 'auto' }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="usuario">Usuario</label>
                        <input
                            name="usuario"
                            placeholder="Ingrese nombre de usuario"
                            className="form-control"
                            onChange={handleInput}
                        />
                        {errors.usuario && <span className="text-danger">{errors.usuario}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <input
                            name="password"
                            placeholder="Ingrese contraseña"
                            type="password"
                            className="form-control"
                            onChange={handleInput}
                        />
                        {errors.password && <span className="text-danger">{errors.password}</span>}
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Entrar</button>
                </form>
            </div>
        </div>
    )
}

export default Login
