// GenerarPDF.js
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatRemuneracion, cleanRemuneracion2 } from '../Validations/TrabajadoresValidation';

export const generarPDF = (rows, datosSueldo) => {
    const doc = new jsPDF();

    // Encabezado
    doc.setFontSize(14);
    doc.text(String(datosSueldo.empresa), 10, 10);
    doc.setFontSize(10);
    doc.text("Cochrane 130, 2 piso oficina 05", 10, 15);
    doc.text("77.040.719-2", 10, 20);
    doc.text("56 932706706", 10, 25);
    doc.text("dfuentes@idafa.cl", 10, 30);

    doc.setFontSize(12);
    doc.text("Número", 150, 10);
    doc.text(String("CP " +datosSueldo.idCotizacion), 180, 10);
    doc.text("Fecha", 150, 15);
    doc.text(String(datosSueldo.fechaactual), 180, 15);
    const today = new Date();

    // Sumar un mes a la fecha
    today.setMonth(today.getMonth() + 1);

    // Formatear la nueva fecha en dd/mm/aaaa
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');  // Los meses empiezan desde 0, así que sumamos 1
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    doc.text("Valido hasta" , 150, 20);
    doc.text(formattedDate, 180, 20);

    // Sección de datos del cliente
    doc.setFontSize(12);
    doc.setFillColor(169, 169, 169);
    doc.rect(10, 35, 190, 8, "F");
    doc.text("DATOS DEL CLIENTE", 15, 40);

    doc.setFontSize(10);
    doc.text(`Nombre:  ${String(datosSueldo.nombreEmpresa)}`, 10, 50);
    doc.text(`Dirección:  ${String(datosSueldo.direccion)}`, 10, 55);
    doc.text(`RUT:  ${String(datosSueldo.rutempresa)}`, 10, 60);
    doc.text(`Teléfono:  ${String(datosSueldo.telefono)}`, 10, 65);
    doc.text(`E-mail:  ${String(datosSueldo.email)}`, 10, 70);

    const sueldoColumns = ["Descripción", "Valor "+datosSueldo.cantidadHora+" horas extra"];
    const sueldoRows = [
        ["Sueldo Base", `$${formatRemuneracion(String(datosSueldo.sueldoBase))}`],
        ["Gratificación", `$${formatRemuneracion(String(datosSueldo.gratificacion))}`],
        ["Horas extras pp", `$${formatRemuneracion(String(datosSueldo.horaextrapp))}`],
        ["SUBTOTAL IMPONIBLE", `$${formatRemuneracion(String(datosSueldo.subTotalImponible))}`],
        ["Movilización", `$${formatRemuneracion(String(datosSueldo.movilizacion))}`],
        ["Colación", `$${formatRemuneracion(String(datosSueldo.TotalColacion))}`],
        ["SIS", `$${formatRemuneracion(String(datosSueldo.valorSIS))}`],
        ["Mutual", `$${formatRemuneracion(String(datosSueldo.valorMututal))}`],
        ["Seguro Cesantía", `$${formatRemuneracion(String(datosSueldo.valorseguroCesantia))}`],
        ["COSTO TOTAL NETO", `$${formatRemuneracion(String(datosSueldo.valorCostoTotalNeto))}`],
        ["Administración general", `$${formatRemuneracion(String(datosSueldo.administracion))}`],
        ["Indemnización del trabajador y aguinaldo", `$${formatRemuneracion(String(datosSueldo.indemnizacion))}`],
        ["COSTO VARIABLE EPP", `$${formatRemuneracion(String(datosSueldo.TotalEPP))}`],
        [`Utilidad ${formatRemuneracion(String(datosSueldo.utilidad))} %`, `$${formatRemuneracion(String(datosSueldo.CalculoUtilidad))}`],
        ["TOTAL COSTO INGRESO", `$${formatRemuneracion(String(datosSueldo.TotalFijoTrabajador))}`],
    ];

    doc.autoTable({
        startY: 80,  // Tabla de sueldo comienza aquí
        head: [sueldoColumns],
        body: sueldoRows,
        styles: {
            // Configuración de bordes
            lineColor: [0, 0, 0], // Color de la línea (negro)
            lineWidth: 0.1,        // Grosor de las líneas
            halign: 'center',      // Alineación horizontal
            textColor: [0, 0, 0],  // Color del texto (negro)
        },
        headStyles: {
            fillColor: [169, 169, 169], // Color de fondo de las celdas de encabezado
            lineColor: [0, 0, 0], // Color del borde
            lineWidth: 0.1,      // Grosor del borde en el encabezado
        },
        alternateRowStyles: {
            fillColor: [240, 240, 240], // Color alternativo de filas
        }
    });

    const serviceColumns = ["Descripcion", "Cantidad", "Valor Neto", "IVA", "Valor Mes"];
    const serviceRows = rows.map((row) => [
        row.descripcion || "",
        row.cantidad,
        `$${formatRemuneracion(String(row.valorNeto))}`,
        `$${formatRemuneracion(String(row.valorIVA))}`,
        `$${formatRemuneracion(String(row.valorMes))}`,
    ]);

    doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,  // La tabla dinámica comienza después de la tabla anterior
        head: [serviceColumns],
        body: serviceRows,
        styles: {
            lineColor: [0, 0, 0], // Color de la línea (negro)
            lineWidth: 0.1,        // Grosor de las líneas
            halign: 'center',      // Alineación horizontal
            textColor: [0, 0, 0],  // Color del texto (negro)
        },
        headStyles: {
            fillColor: [169, 169, 169], // Color de fondo de las celdas de encabezado
            lineColor: [0, 0, 0], // Color del borde
            lineWidth: 0.1,      // Grosor del borde en el encabezado
        },
        alternateRowStyles: {
            fillColor: [240, 240, 240], // Color alternativo de filas
        }
    });

    // Agregar total de la tabla dinámica
    // doc.text(
    //     `Total costo ingreso $${formatRemuneracion(calcularTotalMes().toString())}`,
    //     10,
    //     doc.lastAutoTable.finalY + 10,
    //     { align: 'center' }
    // );

    // Crear la tabla de productos/servicios

    // Subtotal, IVA y Total
    // Obtén el Sub-total a partir de datosSueldo.TotalFijoTrabajador
    const subtotal = Number(datosSueldo.TotalFijoTrabajador);

    // Calcular el IVA como el 19% del Sub-total
    const iva = cleanRemuneracion2(subtotal) * 0.19;

    // Calcular el Total sumando el Sub-total y el IVA
    const total = subtotal + Math.round(iva);

    // Coloca el texto en el documento PDF
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.text("SUB-TOTAL:", 150, finalY);
    doc.text("$" + formatRemuneracion(String(subtotal)), 180, finalY);

    // Guardar PDF
    doc.save("Cotización_CP_"+ datosSueldo.idCotizacion +".pdf");
};

export default generarPDF;
