import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import './ccss/Sidebar.css';
import { useCookies } from 'react-cookie';
import config from './Componentes/config';

function Sidebar({ imageUrl, isComponentsCollapsed, toggleCollapse, setIsComponentsCollapsed, isUtilitiesCollapsed, setIsUtilitiesCollapsed, isInventario, setisIventatioCollapsed, isPrevencion, setisPrevencionCollapsed, isLogistica, setisLogisticaCollapsed }) {
    const [isSidebarToggled, setIsSidebarToggled] = React.useState(false);
    const [cookies, setCookie] = useCookies(['selectedCard']);
    const [permisosModulos, setPermisosModulos] = useState([]);
    const userId = cookies.userId;

    const toggleSidebar = () => {
        setIsSidebarToggled(!isSidebarToggled);
    };

    useEffect(() => {
        console.log("Cookie userId:", userId); // Verifica que se obtiene correctamente
        if (userId) {
            // Obtener permisos de los módulos del usuario
            Axios.get(`${config.API_URL}/getPermisosModulos`, { params: { userId } })
                .then((response) => {
                    setPermisosModulos(response.data.map(modulo => modulo.Modulo)); // Guardar los nombres de los módulos permitidos
                })
                .catch((error) => {
                    console.error("Error al cargar los permisos:", error);
                });
        } else {
            console.error("No se encontró userId en las cookies");
        }
    }, [userId]);

    const hasPermission = (modulo) => permisosModulos.includes(modulo);
    console.log("id front: " +userId);

    return (
        <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${isSidebarToggled ? 'toggled' : ''}`} id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center">
                <Link className="nav-link" to="/Prehome">
                    <img src={imageUrl} alt="Selected Card" />
                </Link>
            </a>
            <hr className="sidebar-divider my-0" />
            
            {/* Recursos Humanos */}
            {hasPermission("Recursos Humanos") && (
    <li className="nav-item">
        <a 
            className="nav-link" 
            onClick={() => toggleCollapse(setIsComponentsCollapsed)}
        >
            <i className="fas fa-users"></i>
            <span>Recursos Humanos</span>
        </a>
        <div 
            className={`collapse ${isComponentsCollapsed ? '' : 'show'}`} 
            aria-labelledby="headingTwo" 
            data-parent="#accordionSidebar"
        >
            <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Seleccione:</h6>
                {/* Comenta o incluye los enlaces necesarios */}
                {/* <Link className="collapse-item" to="/Contratos"><i className="fas fa-file-signature"></i> Contratos</Link> */}
                <Link className="collapse-item" to="/Compensados">
                    <i className="fas fa-file-invoice-dollar"></i> Compensados
                </Link>
                <Link className="collapse-item" to="/Permisos">
                    <i className="fas fa-indent"></i> Permisos
                </Link>
                {/* <Link className="collapse-item" to="/cards"><i className="fas fa-file-word"></i> Pacto H.H</Link> */}
                {/* <Link className="collapse-item" to="/cards"><i className="fas fa-file-word"></i> Asistencia</Link> */}
                <Link className="collapse-item" to="/Trabajadores">
                    <i className="fas fa-file-word"></i> Trabajadores
                </Link>
            </div>
        </div>
    </li>
)}

            {/* Contabilidad */}
            {hasPermission("Contabilidad") && (
                <li className="nav-item">
                    <a className="nav-link">
                        <i className="fas fa-fw fa-wrench"></i>
                        <span>Contabilidad</span>
                    </a>
                </li>
            )}

            {/* Inventario */}
            {hasPermission("Inventario") && (
                <li className="nav-item">
                    <a className="nav-link">
                        <i className="fas fa-boxes"></i>
                        <span>Inventario</span>
                    </a>
                </li>
            )}

            {/* Prevencion */}
            {hasPermission("Prevención") && (
                <li className="nav-item">
                    <a className="nav-link">
                        <i className="fas fa-hard-hat"></i>
                        <span>Prevención</span>
                    </a>
                </li>
            )}

            {/* Utilidades */}
{hasPermission("Utilidades") && (
    <li className="nav-item">
        <a 
            className="nav-link" 
            onClick={() => toggleCollapse(setisLogisticaCollapsed)}
        >
            <i className="fas fa-truck-loading"></i>
            <span>Utilidades</span>
        </a>
        <div 
            className={`collapse ${isLogistica ? '' : 'show'}`} 
            aria-labelledby="headingUtilities" 
            data-parent="#accordionSidebar"
        >
            <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/Cotizaciones">
                    <i className="fas fa-file-invoice-dollar"></i> Cotizacion personal
                </Link>
                <Link className="collapse-item" to="/CotizacionVenta">
                <i className="fas fa-file-invoice-dollar"></i> Cotizacion venta</Link>
                <Link className="collapse-item" to="/animations">Opcion3</Link>
                <Link className="collapse-item" to="/other">Opcion4</Link>
            </div>
        </div>
    </li>
)}


            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick={toggleSidebar}></button>
            </div>
        </ul>
    );
}

export default Sidebar;


// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Axios from 'axios';
// import './ccss/Sidebar.css';

// function Sidebar({ userId, imageUrl }) {
//     const [isSidebarToggled, setIsSidebarToggled] = React.useState(false);
//     const [permisosModulos, setPermisosModulos] = useState([]);

//     const toggleSidebar = () => {
//         setIsSidebarToggled(!isSidebarToggled);
//     };

//     useEffect(() => {
//         // Obtener permisos de los módulos del usuario
//         Axios.get('/getPermisosModulos', { params: { userId } })
//             .then((response) => {
//                 setPermisosModulos(response.data.map(modulo => modulo.Modulo)); // Guardar los nombres de los módulos permitidos
//             })
//             .catch((error) => {
//                 console.error("Error al cargar los permisos:", error);
//             });
//     }, [userId]);

//     // Función para verificar si un módulo está permitido
//     const hasPermission = (modulo) => permisosModulos.includes(modulo);

//     return (
//         <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${isSidebarToggled ? 'toggled' : ''}`} id="accordionSidebar">
//             <a className="sidebar-brand d-flex align-items-center justify-content-center">
//                 <Link className="nav-link" to="/Prehome">
//                     <img src={imageUrl} alt="Selected Card" />
//                 </Link>
//             </a>
//             <hr className="sidebar-divider my-0" />
            
//             {/* Recursos Humanos */}
//             {hasPermission("Recursos Humanos") && (
//                 <li className="nav-item">
//                     <a className="nav-link">
//                         <i className="fas fa-users"></i>
//                         <span>Recursos Humanos</span>
//                     </a>
//                 </li>
//             )}

//             {/* Contabilidad */}
//             {hasPermission("Contabilidad") && (
//                 <li className="nav-item">
//                     <a className="nav-link">
//                         <i className="fas fa-fw fa-wrench"></i>
//                         <span>Contabilidad</span>
//                     </a>
//                 </li>
//             )}

//             {/* Inventario */}
//             {hasPermission("Inventario") && (
//                 <li className="nav-item">
//                     <a className="nav-link">
//                         <i className="fas fa-boxes"></i>
//                         <span>Inventario</span>
//                     </a>
//                 </li>
//             )}

//             {/* Prevencion */}
//             {hasPermission("Prevención") && (
//                 <li className="nav-item">
//                     <a className="nav-link">
//                         <i className="fas fa-hard-hat"></i>
//                         <span>Prevención</span>
//                     </a>
//                 </li>
//             )}

//             {/* Utilidades */}
//             {hasPermission("Utilidades") && (
//                 <li className="nav-item">
//                     <a className="nav-link">
//                         <i className="fas fa-truck-loading"></i>
//                         <span>Utilidades</span>
//                     </a>
//                 </li>
//             )}

//             <div className="text-center d-none d-md-inline">
//                 <button className="rounded-circle border-0" id="sidebarToggle" onClick={toggleSidebar}></button>
//             </div>
//         </ul>
//     );
// }

// export default Sidebar;
