import React, { useState } from "react"
import { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Sidebar from './SideBar';
import Axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import { validarCampos, validarArchivo, formatRut, cleanRut, formatRutDV } from './Validations/TrabajadoresValidation';
import UserPage from "./UserPage";
import config from './Componentes/config';
import useAuthRedirect from "./Componentes/auth";
import { registrarLog } from './Componentes/logService';

const cardImages = {
  DAFA: "/img/dafaLogo.png",
  FUGA: "/img/fugarLogo.png",
  INVE: "/img/idafaLogo.png",
  TRAN: "/img/extasisLogo.png" 
};

function DestinationPage() {
  useAuthRedirect();
  const [cookies] = useCookies(['selectedCard']);
  const imageUrl = cardImages[cookies.selectedCard]
  const idEmpresa = cookies.idEmpresa;
  const NombreEmpresa = cookies.NombreEmpresa;
  const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
  const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
  const [isInventario, setisIventatioCollapsed] = useState(true);
  const [isPrevencion, setisPrevencionCollapsed] = useState(true);
  const [isLogistica, setisLogisticaCollapsed] = useState(true);
  const toggleCollapse = (collapseSetter) => {
    collapseSetter(prevState => !prevState);
  };
  // Estados para el componente Trabajadores
  const [empresa, setEmpresa] = useState(idEmpresa);
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [afp, setAfp] = useState("");
  const [rut, setRut] = useState("");
  const [prevision, setPrevision] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [comuna, setComuna] = useState("");
  const [cargo, setCargo] = useState("");
  const [tipoContrato, setTipoContrato] = useState("");
  const [vigenciaDesde, setVigenciaDesde] = useState("");
  const [vigenciaHasta, setVigenciaHasta] = useState("");
  const [TrabajadoresList, setTrabajadores] = useState([]);
  const [editar, setEditar] = useState(false);
  const [id, setID] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [TipoArchivo, setTipoArchivo] = useState("");

  const ConfirmaRegistro = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, registrar!'
    }).then((result) => {
      if (result.isConfirmed) {
        addTrabajador();
      }
    });
  }

  const editarTrabajador = (val) => {
    setEditar(true);
    setNombres(val.Nombres);
    setApellidos(val.Apellidos);
    setNacionalidad(val.Nacionalidad);
    setFechaNacimiento(val.FechaNacimiento);
    setEstadoCivil(val.EstadoCivil);
    setAfp(val.AFP);
    setRut(val.Rut);
    setPrevision(val.Prevision);
    setDomicilio(val.Domicilio);
    setComuna(val.Comuna);
    setCargo(val.Cargo);
    setTipoContrato(val.TipoContrato);
    setVigenciaDesde(val.VigenciaDesde);
    setVigenciaHasta(val.VigenciaHasta);
    setID(val.ID_Trabajador);
  }


  const addTrabajador = () => {
    if (!validarCampos({
      empresa, nombres, apellidos, nacionalidad, fechaNacimiento, estadoCivil,
      afp, rut, prevision, domicilio, comuna, cargo,
      tipoContrato, vigenciaDesde, vigenciaHasta
    })) {
      Swal.fire(
        'Advertencia',
        'Por favor, completa todos los campos obligatorios.',
        'warning'
      );
      return; // Si faltan campos, no procede con el registro
    }
    const rutSinFormato = cleanRut(rut);
    Axios.post(`${config.API_URL}/CreaTrabajador`, {
      empresa,
      nombres,
      apellidos,
      nacionalidad,
      fechaNacimiento,
      estadoCivil,
      afp,
      rut: rutSinFormato,
      prevision,
      domicilio,
      comuna,
      cargo,
      tipoContrato,
      vigenciaDesde,
      vigenciaHasta
    }).then(() => {
      console.log(rutSinFormato);
      getTrabajadores();
      limpiarCampos();
      Swal.fire(
        '¡Éxito!',
        'El trabajador ha sido registrado exitosamente.',
        'success'
      );
      registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Registro de trabajador: ${nombres} ${apellidos}`,
        Estado: 'Éxito',
      });

    }).catch((error) => {
      registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Registro de trabajador: ${nombres} ${apellidos}`,
        Estado: 'Fallo',
      });

      Swal.fire(
        'Error',
        `No se pudo registrar el trabajador: ${error.message}`,
        'error'
      );
    });
  };

  const UpdateTrabajador = async () => {
    try {
      if (!validarCampos({
        empresa, nombres, apellidos, nacionalidad, fechaNacimiento, estadoCivil,
        afp, rut, prevision, domicilio, comuna, cargo,
        tipoContrato, vigenciaDesde, vigenciaHasta
      })) {
        Swal.fire(
          'Advertencia',
          'Por favor, completa todos los campos obligatorios.',
          'warning'
        );
        return; // Si faltan campos, no procede con el registro
      }
      const rutSinFormato = cleanRut(rut);
      await Axios.put(`${config.API_URL}/ActualizaTrabajador`, {
        id,
        empresa,
        nombres,
        apellidos,
        nacionalidad,
        fechaNacimiento,
        estadoCivil,
        afp,
        rut: rutSinFormato,
        prevision,
        domicilio,
        comuna,
        cargo,
        tipoContrato,
        vigenciaDesde,
        vigenciaHasta
      });
      getTrabajadores();
      limpiarCampos();
      Swal.fire(
        '¡Éxito!',
        'El trabajador ha sido actualizado exitosamente.',
        'success'
      );
      console.log('Registrando log...');
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Update de trabajador: ${id}`,
        Estado: 'Éxito',
      });
    } catch (error) {
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Update de trabajador: ${nombres} ${apellidos} - error ${error.message}`,
        Estado: 'Fallo',
      });
      Swal.fire(
        'Error',
        `No se pudo actualizar el trabajador: ${error.message}`,
        'error'
      );
    }
  };
  

  const DeleteTrabajador = async (id) => {
    try {
      const res = await Swal.fire({
        title: '¡Cuidado!',
        html: '¿Está seguro de eliminar al trabajador?.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!'
      });
  
      if (res.isConfirmed) {
        await Axios.delete(`${config.API_URL}/EliminaTrabajador/${id}`);
        await registrarLog({
          Usuario: cookies.usuario,
          Modulo: 'Trabajadores',
          Detalle: `Delete de trabajador: ${id}`,
          Estado: 'Éxito',
        });
        getTrabajadores();
        limpiarCampos();
        
        Swal.fire(
          'Eliminado!',
          'El trabajador ha sido eliminado exitosamente',
          'success'
        );
      }
    } catch (error) {
      console.error('Error al eliminar al trabajador:', error);
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Delete de trabajador: ${id} - error ${error}`,
        Estado: 'Fallo',
      });
      Swal.fire(
        '¡Algo pasó!',
        'Hubo un error al eliminar al trabajador. Intente nuevamente.',
        'error'
      );
    }
  }
  

  const limpiarCampos = () => {
    setNombres("");
    setApellidos("");
    setNacionalidad("");
    setFechaNacimiento("");
    setEstadoCivil("");
    setAfp("");
    setRut("");
    setPrevision("");
    setDomicilio("");
    setComuna("");
    setCargo("");
    setTipoContrato("");
    setVigenciaDesde("");
    setVigenciaHasta("");
    setTipoArchivo("");
    setEditar(false);
  }

  const getTrabajadores = () => {
    Axios.get(`${config.API_URL}/getTrabajadores?id=${idEmpresa}`)
      .then(res => {
        console.log(res.data); // Verifica la estructura de la respuesta
        if (Array.isArray(res.data)) {
          setTrabajadores(res.data); // Asegúrate de que sea un array
        } else {
          console.error('La respuesta no es un array:', res.data);
          setTrabajadores([]); // Establece un array vacío si no es un array
        }
      })
      .catch(err => {
        console.error("Error al obtener los datos:", err);
      });
  }

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onFileUpload = async () => {
    const mensajeError = validarArchivo(selectedFile, TipoArchivo);

    if (mensajeError) {
      Swal.fire('Advertencia', mensajeError, 'warning');
      return; // Detiene la función si falla la validación
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("Rut", rut); // Suponiendo que 'rut' está disponible en el estado
    formData.append("TipoArchivo", TipoArchivo); // Suponiendo que 'rut' está disponible en el estado
    formData.append("FechaCarga", moment().format('YYYY-MM-DD HH:mm:ss')); // Agrega 'apellidos' si está disponible
    formData.append("UsuarioCarga", cookies.usuario); // Suponiendo que 'mail' contiene la fecha de carga
    try {
      const response = await Axios.post(`${config.API_URL}/SubeArchivo/${rut}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire(
        '¡Éxito!',
        'El archivo ha sido subido exitosamente.',
        'success'
      );
      fetchFiles();
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Agrega archivo de trabajador: ${id}`,
        Estado: 'Exito',
      });
    } catch (error) {
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Trabajadores',
        Detalle: `Delete archivo de trabajador: ${id}`,
        Estado: 'Error',
      });
      Swal.fire(
        '¡Algo pasó!',
        'El archivo no ha podido subirse.',
        'error'
      );
    }
  }

  const [files, setFiles] = useState([]);
  const [archivosDB, setArchivosDB] = useState([]);

  const fetchFiles = async () => {
    if (rut) {
      try {
        const response = await Axios.get(`${config.API_URL}/CargaArchivos/${rut}`);
        setFiles(Array.isArray(response.data.archivosDirectorio) ? response.data.archivosDirectorio : []);
        setArchivosDB(Array.isArray(response.data.archivosDB) ? response.data.archivosDB : []);
      } catch (error) {
        console.error("Error fetching files", error);
      }
    }
  };

  const deleteFile = (id) => {
    Swal.fire({
      title: '¡Cuidado!',
      html: '¿Está seguro de eliminar el archivo?.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          await Axios.delete(`${config.API_URL}/EliminaArchivo/${id}`);
          Swal.fire(
            'Eliminado!',
            'El trabajador ha sido eliminado exitosamente',
            'success'
          );
          fetchFiles();
          await registrarLog({
            Usuario: cookies.usuario,
            Modulo: 'Trabajadores',
            Detalle: `Delete archivo de trabajador: ${id}`,
            Estado: 'Exito',
          });
        } catch (error) {
          await registrarLog({
            Usuario: cookies.usuario,
            Modulo: 'Trabajadores',
            Detalle: `Delete archivo de trabajador: ${id} - error: ${error}`,
            Estado: 'Error',
          });
          Swal.fire(
            'Error!',
            'Hubo un problema al eliminar el archivo. Por favor intente nuevamente.',
            'error'
          );
          console.error('Error al eliminar el archivo:', error);  // Esto es útil para depuración
        }
      }
    });
  };
  

  const handleRutChange = (event) => {
    const formattedRut = formatRut(event.target.value);
    setRut(formattedRut);
  };

  useEffect(() => {
    fetchFiles(); // Cargar los archivos cuando cambie el 'rut'
  }, [rut]);  // Se ejecutará cada vez que 'rut' cambie


  useEffect(() => {
    getTrabajadores();
  }, []);

  return (
    <body id="page-top">
      <div id="wrapper">
        <Sidebar
          imageUrl={imageUrl}
          isComponentsCollapsed={isComponentsCollapsed}
          toggleCollapse={toggleCollapse}
          setIsComponentsCollapsed={setIsComponentsCollapsed}
          isUtilitiesCollapsed={isUtilitiesCollapsed}
          setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
          isInventario={isInventario}
          setisIventatioCollapsed={setisIventatioCollapsed}
          isPrevencion={isPrevencion}
          setisPrevencionCollapsed={setisPrevencionCollapsed}
          isLogistica={isLogistica}
          setisLogisticaCollapsed={setisLogisticaCollapsed}
        />
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <UserPage cookies={cookies} />
            <div class="container-fluid">
              <div class="row">
                {/* CARDS A PARTIR DE AQUI */}
                <div class="col-lg-12">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary">Registro de nuevo trabajador</h6>
                    </div>
                    <div class="card-body">
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">Nombres:</span>
                        <input type="text" class="form-control" value={nombres} placeholder="Ingrese ambos nombres" onChange={(event) => { setNombres(event.target.value); }}></input>
                        <br />
                        <span class="input-group-text" id="basic-addon1">Apellidos:</span>
                        <input type="text" class="form-control" value={apellidos} placeholder="Ingrese ambos nombres" onChange={(event) => { setApellidos(event.target.value); }}></input>
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">Nacionalidad:</span>
                        <input type="text" class="form-control" value={nacionalidad} placeholder="Ingrese nacionalidad" onChange={(event) => { setNacionalidad(event.target.value); }}></input>
                        <span class="input-group-text" id="basic-addon1">Fecha nacimiento:</span>
                        <input type="date" class="form-control" value={fechaNacimiento} placeholder="Ingrese fecha" onChange={(event) => { setFechaNacimiento(event.target.value); }}></input>
                        <span class="input-group-text" id="basic-addon1">Estado civil:</span>
                        <input type="text" class="form-control" value={estadoCivil} placeholder="Ingrese estado civil" onChange={(event) => { setEstadoCivil(event.target.value); }}></input>
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">AFP:</span>
                        <select class="form-control" name="afp" value={afp} onChange={(event) => setAfp(event.target.value)}>
                          <option value="none">Seleccione:</option>
                          <option value="AFP Capital">AFP Capital</option>
                          <option value="AFP Cuprum">AFP Cuprum</option>
                          <option value="AFP Habitat">AFP Habitat</option>
                          <option value="AFP Modelo">AFP Modelo</option>
                          <option value="AFP PlanVital">AFP PlanVital</option>
                          <option value="AFP Provida">AFP Provida</option>
                          <option value="AFP Uno">AFP Uno</option>
                        </select>
                        <span class="input-group-text" id="basic-addon1">Rut:</span>
                        <input type="text" class="form-control" value={rut} placeholder="Ingrese rut trabajador" onChange={handleRutChange}></input>
                        <span class="input-group-text" id="basic-addon1">Previsión:</span>
                        <select class="form-control" name="afp" value={prevision} onChange={(event) => setPrevision(event.target.value)}>
                          <option value="none">Seleccione:</option>
                          <option value="Fonasa">Fonasa</option>
                          <option value="Isapre Nueva Masvida">Isapre Nueva Masvida</option>
                          <option value="Isapre Codelco Ltda">Isapre Codelco Ltda</option>
                          <option value="Isapre Cruz del norte">Isapre Cruz del norte</option>
                          <option value="Isapre Cruz Blanca S.A.">Isapre Cruz Blanca S.A.</option>
                          <option value="Isapre Banmedica">Isapre Banmedica</option>
                          <option value="Isapre VidaTres">Isapre VidaTres</option>
                          <option value="Isapre Colmena">Isapre Colmena</option>
                        </select>
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">Domicilio:</span>
                        <input type="text" class="form-control" value={domicilio} placeholder="Ingrese direccion" onChange={(event) => setDomicilio(event.target.value)}></input>
                        <span class="input-group-text" id="basic-addon1">Comuna:</span>
                        <input type="input" class="form-control" value={comuna} placeholder="Ingrese comuna" onChange={(event) => setComuna(event.target.value)}></input>
                        <span class="input-group-text" id="basic-addon1">Cargo:</span>
                        <input type="text" class="form-control" value={cargo} placeholder="Ingrese cargo" onChange={(event) => setCargo(event.target.value)}></input>
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">Tipo contrato:</span>
                        <select class="form-control" name="afp" value={tipoContrato} onChange={(event) => setTipoContrato(event.target.value)}>
                          <option value="none">Seleccione:</option>
                          <option value="Plazo fijo">Plazo fijo</option>
                          <option value="Indefinido">Indefinido</option>
                        </select>
                        <span class="input-group-text" id="basic-addon1">desde:</span>
                        <input type="date" class="form-control" value={vigenciaDesde} onChange={(event) => setVigenciaDesde(event.target.value)}></input>
                        <span class="input-group-text" id="basic-addon1">Hasta:</span>
                        <input type="date" class="form-control" value={vigenciaHasta} onChange={(event) => setVigenciaHasta(event.target.value)}></input>
                      </div>
                      <div class="card-footer text-muted">
                        {
                          editar ?
                            <div>
                              <button className='btn btn-danger m-2' onClick={limpiarCampos}>Cancelar</button>
                              <button className='btn btn-warning m-2' onClick={UpdateTrabajador}>Actualizar trabajador</button>
                            </div>
                            : <button className='btn btn-success' onClick={ConfirmaRegistro}>Registrar trabajador</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary">Archivos trabajador</h6>
                    </div>
                    <div class="card-body">
                      <label>Archivos asociados al trabajador: {nombres} {apellidos}</label>
                      <div>
                        <div class="table-responsive">
                          <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Tipo Archivo</th>
                                <th>Fecha Carga</th>
                                <th>Usuario Carga</th>
                                <th>Archivo</th>
                                <th>Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {archivosDB.length > 0 ? (
                                archivosDB.map((archivo, index) => (
                                  <tr key={index}>
                                    <td>{archivo.ID_Registro}</td>
                                    <td>{archivo.Tipo_Archivo}</td>
                                    <td>{moment(archivo.Fecha_Carga).format('DD/MM/YYYY')}</td>
                                    <td>{archivo.Usuario_Carga}</td>
                                    <td>
                                      {/* Usa el nombre de archivo de la base de datos para el enlace de descarga */}
                                      <a
                                        href={`${config.API_URL}/uploads/${rut}/${encodeURIComponent(archivo.Nombre_Archivo)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {archivo.Nombre_Archivo} {/* Muestra el nombre del archivo */}
                                      </a>
                                    </td>
                                    <td><button type="button" onClick={() => { deleteFile(archivo.ID_Registro); }} className="btn btn-danger">Eliminar</button></td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4">No hay archivos en la base de datos.</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br></br>

                      <div class="card-footer text-muted">
                        {editar ? (
                          <div>
                            <label class="form-label">Ingresar archivo trabajador</label>
                            <div className="input-group mb-3">
                              <span class="input-group-text" id="basic-addon1">Tipo archivo:</span>
                              <select className="form-control" name="TipoArchivo" onChange={(event) => {
                                const value = event.target.value;
                                setTipoArchivo(value === "Otros" ? "Otros:" : value);
                              }}>
                                <option value="none">Seleccione:</option>
                                <option value="Contrato">Contrato</option>
                                <option value="Compensados">Compensados</option>
                                <option value="Compensados">Permisos</option>
                                <option value="Anexos">Anexos</option>
                                <option value="Otros">Otros</option>
                              </select>
                              {TipoArchivo.startsWith("Otros:") && (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Especifique tipo de archivo"
                                  value={TipoArchivo.replace("Otros:", "")} // Muestra solo el contenido manual
                                  onChange={(event) => setTipoArchivo(`Otros:${event.target.value}`)} // Actualiza el texto manualmente
                                />
                              )}
                              <input type="file" className="form-control" onChange={onFileChange} />
                            </div>
                            <button className="btn btn-success" onClick={onFileUpload}>Subir</button>
                          </div>
                        ) : (
                          <label className="form-label">Seleccione un trabajador para subir un archivo.</label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <br></br>
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary">Listado de trabajadores de {NombreEmpresa}</h6>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Nombre completo</th>
                              <th>Nacionalidad</th>
                              <th>Fecha Nacimiento</th>
                              <th>Estado civil</th>
                              <th>AFP</th>
                              <th>Rut</th>
                              <th>Previsión</th>
                              <th>Domicilio</th>
                              <th>Comuna</th>
                              <th>Cargo</th>
                              <th>Tipo contrato</th>
                              <th>Desde</th>
                              <th>Hasta</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              Array.isArray(TrabajadoresList) && TrabajadoresList.length > 0 ? (
                                TrabajadoresList.map((val, key) => (
                                  <tr key={key}>
                                    <th scope="row">{val.ID_Trabajador}</th>
                                    <td data-label="Nombre completo">{val.Nombres + ' ' + val.Apellidos}</td>
                                    <td data-label="Nacionalidad">{val.Nacionalidad}</td>
                                    <td data-label="Fecha Nacimiento">{moment(val.FechaNacimiento).format('DD/MM/YYYY')}</td>
                                    <td data-label="Estado civil">{val.EstadoCivil}</td>
                                    <td data-label="AFP">{val.AFP}</td>
                                    <td data-label="Rut">{formatRutDV(val.Rut)}</td>
                                    <td data-label="Previsión">{val.Prevision}</td>
                                    <td data-label="Domicilio">{val.Domicilio}</td>
                                    <td data-label="Comuna">{val.Comuna}</td>
                                    <td data-label="Cargo">{val.Cargo}</td>
                                    <td data-label="Tipo contrato">{val.TipoContrato}</td>
                                    <td data-label="Desde">{moment(val.VigenciaDesde).format('DD/MM/YYYY')}</td>
                                    <td data-label="Hasta">{moment(val.VigenciaHasta).format('DD/MM/YYYY')}</td>
                                    <td data-label="Acciones">
                                      <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" onClick={() => { editarTrabajador(val); }} className="btn btn-info">Seleccionar</button>
                                        <button type="button" onClick={() => { DeleteTrabajador(val.ID_Trabajador); }} className="btn btn-danger">Eliminar</button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr><td colSpan="15">No hay trabajadores disponibles</td></tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default DestinationPage;