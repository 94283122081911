import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
    const [, , removeCookie] = useCookies(["usuario"]); // Ignora los parámetros que no usas
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("usuario"); // Elimina la cookie
        navigate("/login"); // Redirige al login
    };

    return logout; // Devuelve la función
};

export default useLogout;