import React from 'react'
import { useState } from "react"
import Axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './Componentes/config';
import useAuthRedirect from "./Componentes/auth";

function Registro() {
    useAuthRedirect();
    const [Nombres, setNombres] = useState("");
    const [Apellidos, setApellidos] = useState("");
    const [Mail, setMail] = useState("");
    const [Usuario, setUsuario] = useState("");
    const [Password, setPassword] = useState("");
    const [UsuariosList, setUsuarios] = useState([]);

    const add = () => {
        Axios.post(`${config.API_URL}/create`, {
            nombres: Nombres,
            apellidos: Apellidos,
            mail: Mail,
            usuario: Usuario,
            password: Password
        }).then(() => {
            getUsuarios();
            alert("Usuario registrado")
        });
    }

    const getUsuarios = () => {
        Axios.get(`${config.API_URL}/usuarios`).then((response) => {
            setUsuarios(response.data);
        });
    }
    return (
        <div class="container">
            <div class="card text-center">
                <div class="card-header">
                    Gestion de usuarios
                </div>
                <div className="card-body">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Nombres:</span>
                        <input type="text" class="form-control" placeholder="Ingrese ambos nombres" onChange={(event) => { setNombres(event.target.value); }}></input>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Apellidos:</span>
                        <input type="text" class="form-control" placeholder="Ingrese ambos nombres" onChange={(event) => { setApellidos(event.target.value); }}></input>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Mail:</span>
                        <input type="text" class="form-control" placeholder="Ingrese ambos nombres" onChange={(event) => { setMail(event.target.value); }}></input>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Usuario:</span>
                        <input type="text" class="form-control" placeholder="Ingrese ambos nombres" onChange={(event) => { setUsuario(event.target.value); }}></input>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Password:</span>
                        <input type="text" class="form-control" placeholder="Ingrese ambos nombres" onChange={(event) => { setPassword(event.target.value); }}></input>
                    </div>
                </div>
                <div class="card-footer text-muted">
                    <button className='btn btn-success' onClick={add}>Registrar usuario</button>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nombre completo</th>
                        <th scope="col">Mail</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Password</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        UsuariosList.map((val, key) => {
                            return <tr>
                                <th scope="row">{val.ID}</th>
                                <td>{val.Nombres} {val.Apellidos}</td>
                                <td>{val.Mail}</td>
                                <td>{val.Usuario}</td>
                                <td>{val.Password}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Registro
