function ValidatingLogin(values){
    let error = {}
    if (values.usuario === ''){
        error.usuario = 'Campo usuario no puede estar vacio'
    }
    else {
        error.usuario = ''
    }

    if (values.password === ''){
        error.password = 'Contraseña no puede estar vacia'
    } else {
        error.password = ''
    }
    return error;
}

export default ValidatingLogin;